<template>
  <div class="index-page">
    <SiteHeaderHome />
    <main class="content _sr-only">
      <h1 class="title">Home</h1>
      <div class="portfolio-project" v-for="i in portfolioProjects" :key="i.id">
        <NuxtLink
          :to="`/portfolio/${i.slug}`"
          class="link"
          :aria-label="`Visit Portfolio Project ${i.title}`"
        >
          <h2 class="title">{{ i.title }}</h2>
        </NuxtLink>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import SiteHeaderHome from '~/components/site-header/SiteHeaderHome.vue';

const nuxtApp = useNuxtApp();

const { data, error } = await useAsyncGql({
  operation: 'GetAllPortfolioProjects'
});

if (error.value || !data.value || !data.value.allPortfolioProjects) {
  throw showError({
    statusCode: 404,
    statusMessage: `Page not found`
  });
}

const portfolioProjects = computed(function () {
  return data.value?.allPortfolioProjects ?? [];
});

onMounted(function () {
  if (nuxtApp.$Webgl.webglExperience.value) {
    nuxtApp.$Webgl.webglExperience.value.floatingPlanesExperience.play({ delay: 2000 });
  }

  prefetchTeaserImages();
});

function prefetchTeaserImages() {
  for (let i = 0; i < portfolioProjects.value.length; i++) {
    const p = portfolioProjects.value[i];

    const img = new Image();

    if (p.teaserImage.responsiveImage?.src) {
      img.src = p.teaserImage.responsiveImage?.src;

      img.addEventListener('load', function () {
        console.log('image laoded');
      });

      console.log('prefetch', img.src);
    }
  }
}
</script>

<style>
.index-page {
  user-select: none;

  * {
    user-select: none;
  }
}
</style>

<style scoped lang="scss">
.index-page {
  > .content {
  }
}
</style>
